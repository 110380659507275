"use strict";

window.addEventListener(
	"load",
	async (): Promise<void> => {
		const actionTexts = Array.prototype.slice.call(
			document.querySelectorAll(".owSubmitActionText")
		) as HTMLButtonElement[];
		actionTexts.forEach(
			(button: HTMLButtonElement): void => {
				button.addEventListener("click", onActionTextClicked);
			}
		);

		const actionWidth = Array.prototype.slice.call(
			document.querySelectorAll(".owSubmitActionWidth")
		) as HTMLButtonElement[];
		actionWidth.forEach(
			(button: HTMLButtonElement): void => {
				button.addEventListener("click", onActionWidthClicked);
			}
		);

		const forgotPassword = document.querySelector("#owForgotSubmit") as HTMLElement;
        forgotPassword.onclick = onForgotPassword;

		function onActionTextClicked(event: Event) {
			const button = event.currentTarget as HTMLElement;
			if (button) {
				button.innerHTML = button.getAttribute("data-action-text") as string;
			}
		}
		
		function onActionWidthClicked(event: Event) {
			const button = event.currentTarget as HTMLElement;
			if (button) {
				button.style.width = `${button.getAttribute("data-action-width") as string}px`;
			}
		}

		function onForgotPassword(e) {
			if(e.currentTarget.innerHTML !== "Forgot Password?") return;
			const user = (document.querySelector("#username") as HTMLInputElement).value;
			window.location.href = window.location.protocol + "//" + window.location.host + '/login/forgot?user=' + user;
		}
	}
);


